
import { useEffect, useState } from 'react';
import { showToastMessage } from '../../helpers/toastr.js';
import wrapModalfn from '../../components/modal/modal.js';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol.js';
import Loading from '../../components/loader/Loading.jsx';
import SearchFilter from '../../components/pagination/SearchFilter.jsx';
import DataTable from 'react-data-table-component';
import checkPermission from '../../helpers/checkPermission.js';
import ConfirmationPopupButton from '../../components/ConfirmationPopup/ConfirmationPopupButton.jsx';
import ReasonTooltip from '../customer/ReasonTooltip.jsx';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { handleFeedBacklisting } from '../../services/feedback.js';
import { conditionalRowStyles } from "../../helpers/conditionalRowStyles";
import { formatDate } from "../../helpers/formatDate";

const Listing = ({ selectGlobalOrg }) => {
    const userData = useSelector((state) => state.userData);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);




    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selection, setSelection] = useState("Last-30-Days");

    const [dateInputValue, setDateInputValue] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;
    const [filterDate, setFilterDate] = useState('');

    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };

    useEffect(() => {
        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue]);


    const fetchData = async (page, per_page, searchText) => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        try {
            const result = await handleFeedBacklisting(initialOrgIds, page, per_page, searchText);
            if (result.data) {

                const feedbackData = result?.data?.data;
                console.log(feedbackData, ' this is my feedback data');
                setData(feedbackData);
                setLoading(false)

            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error?.response?.data?.message)
        } finally {
            setLoading(false)
        }

    }
    const columns = [
        {
            name: 'ID',
            selector: (row, index) => index + 1,
        },
        {
            name: 'Customer Name',
            selector: row => row?.customer?.name,
            sortable: true,
        },

        {
            name: 'Email',
            selector: row => row.customer?.email,
            sortable: true,

        },
        {
            name: 'Type',
            selector: row => row?.feedback_type,
            sortable: true,
        },

        {
            name: 'Message',
            cell: row => {
                if (row.message) {
                    return <ReasonTooltip reason={row.message} />;
                }
                return '';
            },
            sortable: true,
        },

        {
            name: 'Date',
            selector: row => row.created_at ? formatDate(row.created_at) : '',
            sortable: true,
        },

    ];


    return (
        <>


            <div className="card">
                <div className="card-header bg-transparent">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="card-title heading-h3 mb-0">Feedback</h2>
                        </div>
                    </div>
                </div>
                <div className="card-body">


                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={25}
                                paginationServer
                                paginationTotalRows={data?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                paginationComponentOptions={{
                                    rangeSeparatorText: 'of',
                                    noRowsPerPage: true,
                                }}
                                {...(hasAdminRole && { conditionalRowStyles })}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default Listing;