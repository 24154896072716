import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import checkPermission from "../../helpers/checkPermission";
import { useSelector } from "react-redux";
import wrapModalfn from "../../components/modal/modal";
import { debounce, orderBy } from 'lodash';
import { showToastMessage } from "../../helpers/toastr";
import { handleAdvancePackage, handleAdvancePackagePDF, handleDeleteAdvancePackage } from "../../services/packageServices";
import Loading from "../../components/loader/Loading";
import DataTable from "react-data-table-component";
import SearchFilter from "../../components/pagination/SearchFilter";
import { formatDate } from "../../helpers/formatDate";
import { formatTime } from "../../helpers/formatTime";
import { formatAmountSymbol } from "../../helpers/formatAmountSymbol";
import ConfirmationPopupButton from "../../components/ConfirmationPopup/ConfirmationPopupButton";
import showDeletePopup from "../../helpers/showDeletePopup";
import { handleGetOtp, handleOtpVerfication } from "../../services/billingService";
import showOtpVerficationPopup from "../../helpers/showOtpVerficationPopup";
import showEditPopup from "../../helpers/showEditPopup";
import AdvancePackageInvoice from "../InvoicesPrint/AdvancePackageInvoice";
import IframeModal from "../../components/modal/IframeModal";


const AdvancePackageListing = ({ selectGlobalOrg, filterData }) => {
    const [typeBTN, setTypeBTN] = useState('view');
    const modalHeading = typeBTN === 'view' ? 'Billing' : typeBTN === 'edit' ? 'Client Job Card' : 'Job Card';

    const [iframeModalIsOpen, iframeSetIsOpen] = useState(false);
    const userData = useSelector((state) => state.userData);
    const [UUID, setUUID] = useState('');
    const [popupID, setPopupID] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pdfData, setPdfData] = useState([]);
    const [numPages, setNumPages] = useState(1);
    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;

    const navigate = useNavigate();

    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };
    function openIframeModal(type) {
        iframeSetIsOpen(true);
    }

    function closeIframeModal() {
        iframeSetIsOpen(false);
    }
    const iframeHeight = `${numPages * 700}px`;

    const columns = [
        {
            name: 'Sr No',
            selector: row => row?.id,
            sortable: true,
        },
        {
            name: 'Package Name',
            selector: row => row.services?.[0]?.package_name || row?.package_name || '',
            sortable: true,
        },
        {
            name: 'Customer',
            selector: row => row.customer?.name || '',
            sortable: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.customer?.phone_number,
            sortable: true,
        },
        {
            name: 'Total Services',
            selector: row => row.services ? row.services.length : 0,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date ? formatDate(row.date) : '',
            sortable: true,
        },
        {
            name: 'OutStadning',
            selector: row => row?.out_standing ? formatAmountSymbol(row?.out_standing) : formatAmountSymbol(0),
            sortable: true,
        },
        {
            name: 'Payment Mode',
            selector: row => {
                return row.payment_modes.map(p => p.payment_mode).join(', ');
            },
            sortable: true,
        },
        {
            name: 'Grand Total',
            selector: row => row?.round_off_grand_total ? formatAmountSymbol(row?.round_off_grand_total) : formatAmountSymbol(0),
            sortable: true,
        },
    ];
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {/* Edit Button */}
                {checkPermission(userData, 'Update service-billings') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at) {
                                showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                            } else {
                                handleEdit(row.id)
                            }
                        }}
                    // Disable the button
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}


                <ConfirmationPopupButton
                    handleDelete={() => {
                        if (row.deleted_at) {
                            showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                        } else {
                            handleDelete(row.id)
                        }
                    }}
                />


                <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleDownload(row.id)}><i class="mdi mdi-download menu-icon bodytext-24"></i></button>
            </div>
        ),
    });
    const handleEdit = async (uuid) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    navigate(`/edit-advance-package/${uuid}`, { state: { reason } });
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    navigate(`/edit-advance-package/${uuid}`, { state: { reason } });
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message);
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };
    const handleDelete = async (id) => {
        const reason = await showDeletePopup();
        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteAdvancePackage(id, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage)
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const result = await handleGetOtp(id);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(id, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteAdvancePackage(id, reason);
                            fetchData(currentPage, perPage)
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message)
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Edit operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }

        }



    };
    const handleDownload = async (uuid, pdfType) => {
        try {
            const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, option => option.value);
            const result = await handleAdvancePackagePDF(initialOrgIds, uuid);

            openIframeModal();
            setPdfData(result?.data?.data);

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }

    }

    useEffect(() => {
        if (filterData != undefined) {
            const parsedFilterData = JSON.parse(filterData);
            if (parsedFilterData.length === 0) {
                return;
            }
        }

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue,filterData]);


    const fetchData = async (page, per_page, searchText) => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        console.log(filterData,'fdsfasdf')
        try {
            const result = await handleAdvancePackage(initialOrgIds,filterData, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        } finally {
            setLoading(false)
        }

    }





    const conditionalRowStyles = [
        {
            when: row => row.deleted_at !== null,
            style: {
                backgroundColor: 'rgba(246, 126, 129, 0.20)',
            },
        },
        {
            when: row => row.updated_at !== null && row.deleted_at == null,
            style: {
                backgroundColor: 'rgba(224, 163, 55, 0.20)',
            },
        },
    ]

    const handleJobCard = (type) => {

        setTypeBTN(type);
        openModal();

    }


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div class="row align-items-center ">
                        <div class="col">
                            <h4 className="card-title heading-h3 mb-0">Advance Packacge Listing</h4>
                        </div>
                        <div class="col-auto">
                            <div class="row gap-1">

                                {checkPermission(userData, 'Create service-billings') && (
                                    <div class="col-auto">
                                        <NavLink to="/advance-package" className="btn button-primary btn-icon-left modalButtonCommon">
                                            <span class="icon">
                                                <i className="fa fa-plus-circle"></i>
                                            </span>
                                            New Advance Package
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {loading ? (
                        <Loading />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={data?.data}
                            pagination
                            paginationPerPage={perPage}
                            paginationServer
                            paginationTotalRows={data?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            subHeader
                            subHeaderComponent={
                                <SearchFilter
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    placeholder="Search by Package Name or Customer"
                                />
                            }
                            highlightOnHover
                            paginationComponentOptions={{
                                rangeSeparatorText: 'of',
                                noRowsPerPage: true,
                            }}
                            {...(hasAdminRole && { conditionalRowStyles })}
                        />
                    )}

                </div>

                <div className="card-body">
                    <IframeModal
                        modalIsOpen={iframeModalIsOpen}
                        setIsOpen={iframeSetIsOpen}
                        closeModal={closeIframeModal}
                        customStyles={customStyles}
                        layout={<AdvancePackageInvoice data={pdfData} />}
                    />

                </div>

            </div>



        </>
    );
};
export default AdvancePackageListing;