import checkPermission from '../helpers/checkPermission';
import axiosInstance from './axiosConfig';
const handleFeedBacklisting = async (orgId, page, per_page, searchText) => {
    try {
        const response = await axiosInstance.post(`/feedback/listing?org_id=${orgId}&page=${page}&per_page=${per_page}& search=${searchText} `);
        return response.data;
    } catch (error) {
        return error.response;
    }
}
export {
    handleFeedBacklisting,
};