import styles from "./styles";
import { paymentOption } from "../../helpers/paymentOption";

const BillingInvoice = ({ data }) => {
  console.log(data, 'this is the data ');

  return (
    <>
      <div className="invoice" style={styles.invoice}>
        <table style={styles.width}>
          <tr>
            <td>
              <table style={styles.width}>
                <tr>
                  <td style={styles.textCenter}> <h1 className="heading" style={styles.heading}>{data?.get_org_info?.name}</h1></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="borderb" style={{ ...styles.borderb, ...styles.paddingb }}>
              <table style={styles.width}>
                <tr>
                  <td className="info" style={styles.info}>
                    {(data.date || data.time) && (
                      <p className="info" style={styles.info}>Date: {data.date || ''} {data.time || ''}</p>
                    )}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
              <table style={styles.width}>
                <tr>
                  <td>
                    <table style={styles.width}>
                      <tr>
                        <td>
                          <p className="billno" style={styles.billno}>Bill To:</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="billno" style={styles.billno}>{data.get_customer_info?.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="billno" style={styles.billno}>{data.get_customer_info?.phone_number}</p>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td className="info" style={styles.info}>
                    <table>
                      <tr>
                        <td>
                          <p className="billno" style={styles.billno}>Bill No: {data.bill_no}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {data.get_org_info?.gst_no && data.get_org_info?.legal_name && (
                            <div className="billno" style={styles.billno}>
                              <div className="billno" style={styles.billno}>
                                <span className="billno" style={styles.billno}>GST No: {data.get_org_info.gst_no}</span>
                              </div>
                              <div className="billno" style={styles.billno}>
                                <span className="billno" style={styles.billno}>Legal Name: {data.get_org_info.legal_name}1</span>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table className="invoice-table" style={styles.width}>
                <thead className="theadbg" style={styles.theadbg}>
                  <tr>
                    <th className="theadth" style={styles.theadth}>Service</th>
                    <th className="theadth" style={styles.theadth}>Staff Name</th>
                    <th className="theadth" style={{ ...styles.theadth, ...styles.textCenter }}>Qty</th>
                    <th className="theadth" style={{ ...styles.theadth, ...styles.textRight }}>Price</th>
                    <th className="theadth" style={{ ...styles.theadth, ...styles.textRight }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.get_multiple_services?.map((item, index) => (
                    <tr key={index} className="" style={styles.borderb}>
                      <td className="theadtd" style={styles.theadtd}>{item?.get_service_name?.name || ''}</td>
                      <td className="theadtd" style={styles.theadtd}>{item?.get_staff_name?.name || ''}</td>
                      <td className="theadtd" style={{ ...styles.theadtd, ...styles.textCenter }}>{item.quantity}</td>
                      <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{item.price}</td>
                      <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4" className="theadtd" style={styles.theadtd}>Subtotal</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.sub_total}</td>
                  </tr>
                  {data.payment_mode !== 'pkg' && (
                    <tr>
                      <td colSpan="4" className="theadtd" style={styles.theadtd}>Tax (18%)</td>
                      <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.tax}</td>
                    </tr>
                  )}

                  <tr>
                    <td colSpan="4" className="theadtd" style={styles.theadtd}>Discount ({data.discount} {data.discount_symbol})</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.discount_amount}</td>
                  </tr>
                  {data?.multiple_payment_types?.length > 0 ? (
                    data.multiple_payment_types.map((payment, index) => (
                      <tr key={index}>
                        <td colSpan="4" className="theadtd" style={styles.theadtd}>{paymentOption(payment.payment_mode)}</td>
                        <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                          {payment.price}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="theadtd" style={styles.theadtd}>Payment Mode</td>
                      <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>
                        {data.payment_mode ? paymentOption(data.payment_mode) : data.payment_method}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="4" className="theadtd" style={styles.theadtd}>Total</td>
                    <td className="theadtd" style={{ ...styles.theadtd, ...styles.textRight }}>{data.round_off_total}</td>
                  </tr>

                </tfoot>
              </table>
            </td>
          </tr>
        </table>
      </div>




    </>
  )


}

export default BillingInvoice;