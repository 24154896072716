import checkPermission from '../helpers/checkPermission';
import axiosInstance from './axiosConfig';



const handleBilling = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-billing?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const modifyRequest = async (formData) => {
  try {
    const response = await axiosInstance.post('modify-request', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditBilling = async (uuid) => {
  try {
    const response = await axiosInstance.post('edit-billing', uuid);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleCustomerSearch = async (formData, orgId) => {
  try {
    const response = await axiosInstance.get(`get-customer-search?value=${formData}&orgId=${orgId}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleBillingListing = async (orgId, status,filterData,page,per_page,searchText) => {

  // if(filterData){
  //   var url = `get-billing-listing?filterData=${filterData}`;
  // }else{
    var url = `get-billing-listing?orgId=${orgId}&status=${status}&filterData=${filterData}&page=${page}&per_page=${per_page}&searchText=${searchText}`;
  // }
console.log(url, ' thi is sthe asdfagfesj')
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteBilling = async (uuid,reason) => {
  try {
    
    const response = await axiosInstance.get(`delete-billing?uuid=${uuid}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleGetOtp = async (uuid,reason,requestType) => {
  try {
    const response = await axiosInstance.get(`send-otp?uuid=${uuid}&reason=${reason}&requestType=${requestType}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleOtpVerfication=async(uuid,otp,reason)=>{
  try {
    const response = await axiosInstance.post(`verify-otp?uuid=${uuid}&otp=${otp}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const handleNotificationListing=async(orgId)=>{
  try {
    const response = await axiosInstance.get(`notification-listing?orgId=${orgId}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}


const handleBillingPDF = async (uuid) => {
  try {
    const response = await axiosInstance.get(`download-pdf?uuid=${uuid}`
    // , 
    // {
    //   responseType: 'blob',
    //   headers: {
    //     Accept: 'application/pdf',
    //     'Content-Type': 'application/pdf',
    //   },
    // }
    );
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleCustomerSearch,
  handleBilling,
  handleBillingListing,
  handleDeleteBilling,
  handleBillingPDF,
  handleEditBilling,
  modifyRequest,
  handleOtpVerfication,
  handleGetOtp,
  handleNotificationListing
};