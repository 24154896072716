import { useEffect, useState } from "react";
import { formatAmountSymbol } from "../../helpers/formatAmountSymbol"
import wrapModalfn from "../modal/modal";
import Modal from 'react-modal';
import { selectOptions } from '../../helpers/paymentOption';
import { formatIntger } from "../../helpers/formatIntger";
import { handleAdvanceOutstandings } from "../../services/packageServices";
import { useNavigate } from "react-router-dom";
import { showToastMessage } from "../../helpers/toastr";
Modal.setAppElement('#root')


export const CustomerDetail = ({ selectedCustomer, redeemServices }) => {

    const { modalIsOpen, closeModal, customStyles, type, setIsOpen, fetchData, } =
        wrapModalfn();

    const [btn, setBtn] = useState(false);

    const [errors, setErrors] = useState({});
    const [isVisible, setIsVisible] = useState([false]);

    const [fields, setFields] = useState([
        { selectValue: "", inputValue: "" },
    ]);
      const navigate = useNavigate();

    const headerText = "Pay advance outstanding";

    const [formData, setFormData] = useState({
        uuid: '',
        customerPaidAmount: '',
        id: '',
        paymentMode: "",
        outStandingAmount: '',
        type: type,
        multiplePaymentMethods: "",
    });


    const serviceNamesArray = selectedCustomer.serviceNames
        ? Object.values(selectedCustomer.serviceNames)
        : [];


    const packageDetails = selectedCustomer?.packageDetails || [];



    const handleSelectChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].selectValue = e.target.value;
        if (updatedFields[index].selectValue === "") {
            updatedFields[index].inputValue = ""
        }
        // setFormData({...formData, multiplePaymentMethods:updatedFields})
        setFields(updatedFields);
        const newIsVisible = [...isVisible];
        newIsVisible[index] = true;
        setIsVisible(newIsVisible);
    };

    useEffect(() => {
        setFormData({
            id: Array.isArray(selectedCustomer?.advance_packages) && selectedCustomer.advance_packages.length > 0
                ? selectedCustomer.advance_packages[0].id
                : '',
            customer_id: selectedCustomer?.id ?? '',
            org_id: selectedCustomer?.org_id ?? '',
            customerPaidAmount: '',
            outStandingAmount: '',
            type: 'advance-outstanding',
            paymentMode: '',
        });

        setErrors({});
    }, [modalIsOpen]);



    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.customerPaidAmount.trim()) {
            newErrors.customerPaidAmount = "Customer paid is required";
            isValid = false;
        } else if (parseFloat(formData.customerPaidAmount) === 0) {
            newErrors.customerPaidAmount = "Customer paid cannot be zero";
            isValid = false;
        }

        if (parseFloat(formData.customerPaidAmount) > Math.abs(selectedCustomer?.advance_packages[0]?.out_standing)) {
            newErrors.customerPaidAmount = `Customer paid cannot exceed ${Math.abs(selectedCustomer?.advance_packages[0]?.out_standing)}`;
            isValid = false;
        }

        // Validate payment methods
        const totalPaymentMethodAmount = fields.reduce((sum, field) => sum + parseFloat(field.inputValue || 0), 0);
        if (totalPaymentMethodAmount !== parseFloat(formData.customerPaidAmount)) {
            newErrors.multiplePaymentMethods = `Total of payment methods must equal Customer Paid (${formData.customerPaidAmount})`;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;

        if (name === 'customerPaidAmount') {
            const newOutStandingAmount = selectedCustomer?.advance_packages[0]?.out_standing ?? 0;

            setFormData((prevFormData) => ({
                ...prevFormData,
                outStandingAmount: newOutStandingAmount >= 0 ? newOutStandingAmount : 0,
                [name]: processedValue,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: processedValue,
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const handleMultiInputChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].inputValue = e.target.value;
        if (updatedFields[index].selectValue !== "") {
            setFields(updatedFields);
        }
    };
    const saveBilling = async (type) => {
        if (validateForm()) {
            setBtn(true);

            try {
                const newFormData = { ...formData, multiplePaymentMethods: fields };
                setFormData(newFormData);
                const result = await handleAdvanceOutstandings(newFormData);

                if (result.data) {
                    showToastMessage('success', result.data.message);
                    closeModal();
                    setBtn(false);
                    navigate(`/billing-listing`)
                }
            } catch (error) {
                setBtn(false);
                if (error.response.status === 404) {
                    const errors = error.response.data.message;
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message);
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message);
                }
            }
        }
    };


    return <>

        <div className="customer_detail_header">
            <h5 class="bodytext-20 fontweight-700 text-dark ">Customer Detail</h5>
        </div>
        <address className="text-primary">
            <div className="customer_detail_body">
                <div className="customer_detail_wrap">
                    <p className=""> Customer Name </p>
                    <p className="cust_name">{selectedCustomer?.name || '-----------'}</p>
                </div>
                <div className="customer_detail_wrap">
                    <p className=""> Customer Wallet </p>
                    <p className="cust_wallet">{selectedCustomer?.wallet ? formatAmountSymbol(selectedCustomer?.wallet) : formatAmountSymbol(0)}</p>
                </div>
                <div className="customer_detail_wrap">
                    <p className=""> Customer Outstanding </p>

                    <p className="cust_outstanding">
                        {selectedCustomer?.out_standing
                            ? formatAmountSymbol(Math.abs(selectedCustomer.out_standing))
                            : selectedCustomer?.get_user_subscription?.length
                                ? formatAmountSymbol(
                                    Math.abs(
                                        selectedCustomer.get_user_subscription[
                                            selectedCustomer.get_user_subscription.length - 1
                                        ].outstanding_amount || 0
                                    )
                                )
                                : formatAmountSymbol(0)}
                    </p>

                </div>

                {packageDetails.length > 0 && (
                    <div className="customer_detail_wrap">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Service Name</th>
                                    <th>Services Left</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageDetails.map((pkg, index) => (
                                    <tr key={index}>
                                        <td>{pkg.packageName}</td>
                                        <td>{pkg.servicesLeft}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {selectedCustomer.advance_packages &&
                    selectedCustomer.advance_packages.length > 0 &&
                    selectedCustomer.advance_packages.some(
                        (pkg) => pkg.services && pkg.services.length > 0
                    ) && (
                        <div className="customer_detail_wrap gap-1">
                            <button
                                type="button"
                                id="redeem_package"
                                onClick={redeemServices}
                                className="btn btn-primary btn-icon-left"
                            >
                                Redeem Package
                            </button>

                        </div>
                    )}
                {selectedCustomer.advance_packages &&
                    selectedCustomer.advance_packages.length > 0 &&
                    selectedCustomer.advance_packages.some(pkg => pkg.out_standing > 0) && (
                        <div className="customer_detail_wrap gap-1">
                            <button
                                type="button"
                                id="redeem_package"
                                onClick={() => setIsOpen(true)}  // Use setIsOpen from wrapModalfn
                                className="btn btn-primary btn-icon-left"
                            >
                                Pay Outstanding
                            </button>

                        </div>
                    )}
            </div>
            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    setIsOpen={setIsOpen}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                    className="custom-modal round-15 bg-white"
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
                        <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
                    </div>

                    <form className="mx-auto mt-4 form" style={{ maxWidth: '100%' }}>
                        <div className="row">
                            <label htmlFor="name" className="form-label">
                                Outstanding Balance: {formatAmountSymbol(selectedCustomer?.advance_packages[0]?.out_standing ?? 0)}
                            </label>

                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Customer Pay:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="customerPaidAmount"
                                        value={formData.customerPaidAmount}
                                        onKeyDown={(e) => formatIntger(e)}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Enter Customer Pay"
                                    />
                                    {errors.customerPaidAmount && (
                                        <div className="text-danger">{errors.customerPaidAmount}</div>
                                    )}
                                </div>
                            </div>

                            {/* Payment Mode with Add/Remove Button */}
                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Payment Mode:</label>
                                    {fields.map((field, index) => (
                                        <div key={index} className="row mb-2">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <select
                                                            className="payment_mode form-select form-control"
                                                            name="paymentMode"
                                                            value={field.selectValue}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                                handleSelectChange(e, index);
                                                            }}
                                                        >
                                                            <option value="">Select Payment Mode</option>
                                                            {selectOptions('deleteOption')}
                                                        </select>
                                                    </div>

                                                    {isVisible[index] && field.selectValue !== "" && (
                                                        <div className="col">
                                                            <input
                                                                min="1"
                                                                type="number"
                                                                className="form-control"
                                                                value={field.inputValue}
                                                                onChange={(e) => handleMultiInputChange(e, index)}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Add/Remove Button Logic */}
                                            <div className="col-auto">
                                                {fields.length > 1 && index > 0 ? (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            const updatedFields = fields.filter((_, i) => i !== index);
                                                            setFields(updatedFields);
                                                        }}
                                                        className="button button-primary btn-icon"
                                                    >
                                                        <i className="fa fa-minus"></i>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            setFields([
                                                                ...fields,
                                                                { selectValue: "", inputValue: "" },
                                                            ])
                                                        }
                                                        className="button button-primary btn-icon"
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}

                                    {/* Display Payment Mode Error */}
                                    {errors.paymentMode && (
                                        <div className="text-danger">{errors.paymentMode}</div>
                                    )}

                                    {/* Display Sum Validation Error for Payment Methods */}
                                    {errors.multiplePaymentMethods && (
                                        <div className="text-danger">{errors.multiplePaymentMethods}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12 col-12 text-end">
                                <button
                                    type="button"
                                    disabled={btn}
                                    className="button button-primary button-icon-left"
                                    onClick={() => saveBilling(type)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                </Modal>
            )}


        </address>

    </>


}